import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import SaleState from "./SaleState"
import AssetImage from "../../app/AssetImage"
import salePrice from "./salePrice"

const Multi = ({ refetch, sale }) => {
  const assets = sale.assets
  const collection = sale.collection

  return (
    <>
      <Helmet>
        <title>{`${assets.length} NFT Bundle - #${sale.sale_id}`}</title>
        <meta
          property="og:title"
          content={`${assets.length} NFT Bundle - #${sale.sale_id}`}
        />
      </Helmet>
      <div className="container py-5">
        <div className="row pt-5 pb-2">
          <div className="col-12 mb-5">
            <h2>
              Sale:{" "}
              <span>
                #{sale.sale_id} ({assets.length} NFT Bundle)
              </span>
            </h2>
          </div>
        </div>
        <div className="d-flex flex-row flex-nowrap bg-light bundle">
          {assets.map((asset) => {
            return (
              <div
                className="card card-bundle my-3 m-2 p-3"
                key={asset.asset_id}
              >
                <AssetImage
                  asset={asset}
                  className={"img-fluid mx-auto d-block align-self-center"}
                />
              </div>
            )
          })}
        </div>

        <div className="row px-4">
          <div className="col-sm-4 mt-5 d-grid gap-2 d-sm-block">
            <h4 className="mt-3">PRICE</h4>
            <p>{salePrice(sale)}</p>
            <SaleState refetch={refetch} sale={sale} />
          </div>

          <div className="col-8 mt-5 order-sm-first">
            <h4 className="mt-3">COLLECTION NAME</h4>
            <p>
              <Link to={`/market/${collection.collection_name}`}>
                {collection.name}
              </Link>
            </p>
            <h4 className="mt-3">Bundle Size</h4>
            <p>{assets.length} NFTs</p>
            <h4 className="mt-3">Seller</h4>
            <p>{sale.seller}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Multi
