const marketContract = "atomicmarket"
const playlistContract = "paraffinplst"

const buyTransactions = ({ buyer, saleId, assetIds, price }) => {
  const priceWithSymbol =
    priceForBuy(price.amount, price.token_precision) + " " + price.token_symbol
  const authorization = [
    {
      actor: buyer.accountName,
      permission: buyer.requestPermission,
    },
  ]
  return {
    actions: [
      {
        account: "atomicmarket",
        name: "assertsale",
        authorization,
        data: {
          sale_id: saleId,
          asset_ids_to_assert: assetIds,
          listing_price_to_assert: priceWithSymbol,
          settlement_symbol_to_assert: "8,WAX",
        },
      },
      {
        account: "eosio.token",
        name: "transfer",
        authorization,
        data: {
          from: buyer.accountName,
          to: marketContract,
          quantity: priceWithSymbol,
          memo: "deposit",
        },
      },
      {
        account: marketContract,
        name: "purchasesale",
        authorization,
        data: {
          buyer: buyer.accountName,
          sale_id: saleId,
          intended_delphi_median: "0",
          taker_marketplace: "paraffinmrkt",
        },
      },
    ],
  }
}

const mintPlaylistTransaction = ({
  minter,
  cover_art_image,
  title,
  link,
  message,
}) => {
  const authorization = [
    {
      actor: minter.accountName,
      permission: minter.requestPermission,
    },
  ]
  return {
    actions: [
      {
        account: "eosio.token",
        name: "transfer",
        authorization,
        data: {
          from: minter.accountName,
          to: playlistContract,
          quantity: "3.00000000 WAX",
          memo: "deposit",
        },
      },
      {
        account: playlistContract,
        name: "mint",
        authorization,
        data: {
          minter: minter.accountName,
          cover_art_image,
          title,
          link,
          message,
        },
      },
    ],
  }
}

const confirmTransaction = async ({ rpc, transactionId }) => {
  let found = false
  let trying = true
  let count = 1
  let result

  while (trying) {
    try {
      result = await rpc.history_get_transaction(transactionId)
    } catch (e) {
      result = e?.json
    }
    if (result?.id === transactionId) {
      found = true
      trying = false
    } else {
      if (count >= 10) {
        trying = false
      }
      if (trying) {
        count += 1
        await sleep(1000)
      }
    }
  }
  if (found) {
    return result
  }

  return false
}

const priceForBuy = (amount, token_precision) => {
  const beforeDec = amount.slice(0, -token_precision)
  const afterDec = amount.slice(-token_precision)
  const price = beforeDec + "." + afterDec

  return price
}

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

export { buyTransactions, confirmTransaction, mintPlaylistTransaction }
