import { useContext, useEffect } from "react"
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap"
import { UALContext } from "ual-reactjs-renderer"
import { Link } from "react-router-dom"

const UserNav = ({ activeUser, logout }) => {
  const userName = activeUser.accountName
  return (
    <NavDropdown
      id="navbarDropdown"
      title={<span className="wax-account">{userName}</span>}
    >
      <NavDropdown.Item onClick={logout}>Log Out</NavDropdown.Item>
    </NavDropdown>
  )
}

const LoginButton = ({ showModal }) => {
  return (
    <Nav.Item>
      <Nav.Link onClick={showModal}>Login</Nav.Link>
    </Nav.Item>
  )
}

const MarketNav = () => {
  const { activeUser, activeAuthenticator, showModal, logout } =
    useContext(UALContext)

  useEffect(() => {
    if (activeUser) {
      window?.gtag("event", "login", {
        method: activeAuthenticator?.getName() ?? "Unknown",
      })
    }
  }, [activeUser, activeAuthenticator])

  return (
    <Navbar variant="dark" bg="dark" expand="lg">
      <Container fluid>
        <Navbar.Brand as={Link} to="">
          Paraffin <span>Playlists</span>
        </Navbar.Brand>
        <Navbar.Toggle label="Toggle navigation" />
        <Navbar.Collapse>
          <Nav className="ms-auto mb-2 mb-lg-0">
            <Nav.Item>
              <Nav.Link as={Link} to="">
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="builder">
                Builder
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="playlists">
                Playlists
              </Nav.Link>
            </Nav.Item>
            {activeUser ? (
              <UserNav activeUser={activeUser} logout={logout} />
            ) : (
              <LoginButton showModal={showModal} />
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default MarketNav
