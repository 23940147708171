import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import { useGetAssetQuery } from "../../app/services/assetsApi"
import { ogImageUrl } from "../../app/AssetImage"
import AssetDisplay from "./AssetDisplay"

const NotFound = () => {
  return <div className="text-center my-5 py-5">No asset found</div>
}

const Sale = () => {
  const { assetId } = useParams()

  const { isLoading: loading, error, data: asset } = useGetAssetQuery(assetId)

  if (loading) return <div className="text-center my-5 py-5">Loading...</div>
  if (error) {
    if (error.message === "Asset not found") return <NotFound />
    return <div>Error...</div>
  }

  return (
    <>
      <Helmet>
        <title>{asset.name || "Untitled"}</title>
        <meta property="og:image" content={ogImageUrl(asset)} />
        <meta property="og:title" content={`${asset.name || "Untitled"}`} />
      </Helmet>
      <AssetDisplay asset={asset} />
    </>
  )
}

export default Sale
