import { Link } from "react-router-dom"

import AssetImage from "../../app/AssetImage"
import AssetSkeletons from "../../app/AssetSkeletons"
import salePrice from "../sale/salePrice"

const Listing = ({ listing }) => {
  const asset = listing.assets[0]
  const collection = asset.collection
  return (
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="list-item border p-3">
        <Link to={`/sale/${listing.sale_id}`}>
          {/* ASSET IMAGE */}
          <AssetImage
            asset={asset}
            className={"img-fluid mx-auto d-block"}
            thumbnail
          />
          <div className="row pt-3">
            <div className="col text-truncate">
              {/* COLLECTION NAME */}
              <h4>{collection.name}</h4>
              <p>{asset.name || "Untitled"}</p>
            </div>
            <div className="col text-right">
              <h4 className="text-end">Price</h4>
              <p className="text-end">{salePrice(listing)}</p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

const Listings = ({ isLoading, error, listings = [] }) => {
  if (error) {
    console.log(error)
    return <div>Error...</div>
  }
  return (
    <div className="row my-5">
      {listings.map((listing) => {
        return <Listing listing={listing} key={listing.sale_id} />
      })}
      {isLoading ? <AssetSkeletons count={99} includePrice={true} /> : null}
    </div>
  )
}

export default Listings
