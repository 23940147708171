import {
  mintPlaylistTransaction,
  confirmTransaction,
} from "../../app/services/chainActions"

const mintPlaylist = async (activeUser, data) => {
  if (!activeUser) {
    return
  }
  const transaction = mintPlaylistTransaction({
    minter: activeUser,
    ...data,
  })
  try {
    const result = await activeUser.signTransaction(transaction, {
      broadcast: true,
      blocksBehind: 3,
      expireSeconds: 30,
    })

    const transactionId = result.transactionId
    const confirmedTransaction = await confirmTransaction({
      rpc: activeUser.rpc,
      transactionId,
    })
    if (confirmedTransaction) {
      const logMintTrace = confirmedTransaction.traces.find(
        (trace) =>
          trace?.act?.account === "atomicassets" &&
          trace?.act?.name === "logmint"
      )
      const assetId = logMintTrace?.act?.data?.asset_id
      return { status: "success", assetId }
    } else {
      return {
        status: "unconfirmed",
        error: "not_confirmed",
        message:
          "Could not confirm the transaction, please check your wallet or your transaction history",
      }
    }
  } catch (e) {
    console.log(e.type, e.cause)
    return { status: "error", error: "exception_raised", message: e.message }
  }
}

export default mintPlaylist
