import { Link } from "react-router-dom"
import { Helmet } from "react-helmet-async"

const FAQ = () => {
  return (
    <>
      <Helmet title="FAQ" />
      <div className="container mt-5 mb-5 pb-5 content-static faq">
        <div className="row pt-5 mt-5">
          <div className="col mb-5">
            <h2>Frequently Asked Questions</h2>

            <h4>How do I list something on Paraffin?</h4>
            <p>
              We are in the process of building our own NFT Creator. For now,
              please consider using{" "}
              <a
                href="https://atomichub.io/creator"
                target="_blank"
                rel="noreferrer"
              >
                AtomicHub.io's
              </a>{" "}
              NFT Creator. Then request whitelisting on our{" "}
              <Link to="/submission" title="Submissions">
                submission
              </Link>{" "}
              page.
            </p>
            <h4>Why is my artwork here? I never listed it.</h4>
            <p>
              Paraffin leverages the{" "}
              <a
                href="https://github.com/pinknetworkx/atomicmarket-contract"
                target="_blank"
                rel="noreferrer"
              >
                atomic assets market contract
              </a>{" "}
              "a shared liquidity NFT market smart contract which is used by
              multiple websites… Shared liquidity means that everything which is
              listed on one market also shows on all other markets.” As such,
              any asset that is listed on AtomicHub, NFTHive, WAXStache, etc
              could be purchased on paraffin simply by passing the sale ID to
              the end of the url paraffin.io/sale/.
            </p>

            <h4>This site feels incomplete. Is this it?</h4>
            <p>
              Paraffin is currently in its beta phase. We are in active
              development, and actively working to bring you the best user
              experience possible. Follow us on twitter{" "}
              <a
                href="https://twitter.com/paraffinNFT"
                target="_blank"
                rel="noreferrer"
              >
                @paraffinNFT
              </a>{" "}
              to stay update todate with out latest goings on.
            </p>
            <h4>What's with the Crypto Swatches?</h4>
            <p>
              The team behind{" "}
              <Link to="/" title="Home">
                paraffin.io
              </Link>{" "}
              is the same team behind{" "}
              <a
                href="https://cryptoswatches.com"
                target="_blank"
                rel="noreferrer"
              >
                cryptoswatches.com
              </a>
              . We feel like there exists a good synergy betweent the two
              projects as both seek to promote crypto art and artists.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default FAQ
