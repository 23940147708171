import { createApi } from "@reduxjs/toolkit/query/react"
import { AtomicMarketApi } from "atomicmarket"
import getWaxEnv from "../getWaxEnv"

const marketContract = "atomicmarket"
const marketApis = {}

const collectionWhitelist = [
  "humbledrawin",
  "parisartwork",
  "joecsketches", //@joeychips
  "cryptoflash1", //@CryptoFlash10
  "artbyjordanb",
  "delaunayarts",
  "earthwatcher",
  "fractalarts1",
  "thestartofhi",
  "artbythermod",
  "vilsonsinart",
  "artsofnature",
  "nftboxing123",
  "splattpaints",
  "atomiccomics",
  "motoartworks",
  "waxvaporwave",
  //"unitedartist",
  "travisartcol",
  "mediumformat",
  "cutiewaifu4u",
  "tachitachi13",
  "tankaartlife",
  "kellyburnart",
  "cryptoswatch",
  "plartcollect",
  "cyberdelic33", //@Rickymatthews33
  "signsofpower",
  "thederpycats", //@thederpycats
  "adigitalacid", //@ruenzdidit
  "byronartset1", //@ArtistByron
  "be2saturated", //@FrostCreativ
  "puftcreative", //@PuftCreative
  "maxwellbruno", //@maxwellbrun0
  "sillypsybenx", //@PsySilly
  "konceptonwax", //@konceptonwax
  "waxnftstamps", //@TheNFTStamps
  "catstickerss", //@cutecatstickers
  "virtualakart", //@alaska_virtual
  "bccaldwell13", //@CaldwellCreativ
  "waxsneakkers", //@SnkrsWax
  "mosaicartcrd", //@MosaicNFTArt
  "mosaicstamps", //@MosaicNFTArt
  "dogecoassets", //@DogecoinKid
  "zelifornia13", //@zelifornia
  "cryptopuppyz", //@PupsCrypto
  "ohdopeysophy", //@ohdopeysophy
  "grimfolkscol", //@FolksGrim
  "cryptopuppie", //@apppllleee_pie
  "cuteredpanda", //@Rustytheredpan2
  "urbancltnfts", //@URBANCULTURENFT
  "zanygumballs", //@zanygumballs
  //"godsnlegends", //@godsnlegends
  "aigeometrics", //@ChiapasCrypto
  "demcutefrens", //@DemCuteFrens
  "godlingforge", //@godlingforge
  "creeksassist", //@creeksassistant
  "wrestlingstr", //@WAXSupaStars
  "projctvoodoo", //@frauncesarts
  "deckovarmint", //@floydsvarmints
  "wax.comics", //@wax_comics
  "paintingsnft", //@paintingsnft
  "pixellabs123", //@cryptopenguin10
  "blackbergcmx", //@bretblackberg
]

const getMarketApi = () => {
  const waxEnv = getWaxEnv()

  if (!marketApis.hasOwnProperty(waxEnv.env)) {
    marketApis[waxEnv.env] = new AtomicMarketApi(
      waxEnv.atomicassetsUrl,
      marketContract,
      { fetch }
    )
  }

  return marketApis[waxEnv.env]
}

export const marketApi = createApi({
  reducerPath: "marketApi",
  baseQuery: async ({ method, args }, api, extraOptions) => {
    try {
      const response = await getMarketApi()[method](...args)
      return { data: response }
    } catch (e) {
      return { error: { message: e.message, status: e.status } }
    }
  },
  endpoints: (build) => ({
    getSale: build.query({
      query: (saleId) => {
        const args = ["/v1/sales/" + saleId, { nocache: Date.now() }]
        return { method: "fetchEndpoint", args }
      },
    }),
    getSales: build.query({
      query: ({ sortAttr, sortDir, collectionName, page = 1 }) => {
        const salesParams = { state: 1, page }

        if (collectionName) {
          salesParams.collection_name = collectionName
        } else {
          salesParams.collection_whitelist = collectionWhitelist
        }
        if (sortAttr) salesParams.sort = sortAttr
        if (sortDir) salesParams.order = sortDir

        return {
          method: "getSales",
          args: [salesParams],
        }
      },
    }),
    getFeaturedSales: build.query({
      query: (saleIds) => ({
        method: "getSales",
        args: [
          {
            ids: saleIds,
            state: 1,
          },
        ],
      }),
    }),
  }),
})

export const {
  useGetSaleQuery,
  useGetSalesQuery,
  useLazyGetSalesQuery,
  useGetFeaturedSalesQuery,
} = marketApi
export default getMarketApi
