import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import { fetchInventory } from "./profileThunk"
import Assets from "./Assets"

const Inventory = () => {
  const { userName } = useParams()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchInventory(userName))
  }, [dispatch, userName])

  const { loading, error, inventory } = useSelector((state) => state.profile)

  return (
    <>
      <Helmet>
        <title>{userName}</title>
      </Helmet>
      <div className="px-4 py-5 text-center hero">
        <h1 className="display-6 fw-bold mt-5">Inventory</h1>
        <h4>{userName}</h4>
      </div>

      <div className="container py-5 my-5 px-5">
        {/* <div className="row">
          <div className="col-sm">
            <h6 className="uppercase">Sort By:</h6>
            <button type="button" className="btn btn-outline-dark btn-xs">
              PRICE ↑
            </button>{" "}
            <button type="button" className="btn btn-outline-dark btn-xs">
              PRICE ↓
            </button>{" "}
            <button type="button" className="btn btn-outline-dark btn-xs">
              MINT NO. ↑
            </button>{" "}
            <button type="button" className="btn btn-outline-dark btn-xs">
              MINT NO. ↓
            </button>
          </div>
        </div>

        <div className="row my-5">
          <hr />
        </div> */}
        <Assets isLoading={loading} error={error} assets={inventory} />
      </div>
    </>
  )
}

export default Inventory
