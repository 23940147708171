import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { shallowEqual } from "react-redux"
import {
  createSearchParams,
  useSearchParams,
  useParams,
} from "react-router-dom"
import {
  useGetSalesQuery,
  useLazyGetSalesQuery,
} from "../../app/services/marketApi"
import { useGetCollectionByNameQuery } from "../../app/services/assetsApi"
import Listings from "./Listings"
import { Helmet } from "react-helmet-async"

const sortOptions = [
  { label: "LISTING", attr: "created" },
  { label: "PRICE", attr: "price" },
  { label: "MINT NO.", attr: "template_mint" },
]
const validSortAttrs = sortOptions.map((option) => option.attr)

const queriesEqual = (a, b) => shallowEqual(a, b)

const uniqueBySaleId = (listings) => [
  ...new Map(listings.map((listing) => [listing.sale_id, listing])).values(),
]

const SortButton = ({ label, sortAttr, sortDir, selectedSort, setSort }) => {
  const [selectedSortAttr, selectedSortDir] = selectedSort
  const dirIcon = sortDir === "asc" ? "↓" : "↑"
  const activeClass =
    selectedSortAttr === sortAttr && selectedSortDir === sortDir ? "active" : ""

  return (
    <button
      type="button"
      className={`btn btn-outline-dark btn-xs mr-1 ${activeClass}`}
      data-bs-toggle="button"
      onClick={() => setSort({ attr: sortAttr, dir: sortDir })}
    >
      {label} {dirIcon}
    </button>
  )
}

const Market = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { collectionName } = useParams()
  const maxPage = useRef(1)
  const [loading, setLoading] = useState(true)

  const querySortAttr = validSortAttrs.includes(searchParams.get("sort"))
    ? searchParams.get("sort")
    : "created"
  const querySortDir = ["asc", "desc"].includes(searchParams.get("dir"))
    ? searchParams.get("dir")
    : "desc"

  const [selectedSortAttr, setSortAttr] = useState(querySortAttr)
  const [selectedSortDir, setSortDir] = useState(querySortDir)

  useEffect(() => {
    setSortAttr(querySortAttr)
    setSortDir(querySortDir)
  }, [querySortAttr, querySortDir])

  const resetListings = () => {
    setListings([])
    setLoading(true)
    maxPage.current = 1
  }

  // When the collection name changes (such as navigating from a collection to market page,
  // reset the listings
  useEffect(() => {
    resetListings()
  }, [collectionName])

  const salesQuery = useMemo(
    () => ({
      sortAttr: selectedSortAttr,
      sortDir: selectedSortDir,
      collectionName,
    }),
    [selectedSortAttr, selectedSortDir, collectionName]
  )

  const {
    isFetching: fetchingInitialData,
    error,
    data: initialData,
  } = useGetSalesQuery({ ...salesQuery })

  const [listings, setListings] = useState(initialData)

  useEffect(() => {
    if (!fetchingInitialData) {
      setListings(initialData)
      setLoading(false)
    }
  }, [fetchingInitialData, initialData])

  const [trigger, moreResults] = useLazyGetSalesQuery()

  useEffect(() => {
    if (
      !moreResults.isSuccess ||
      moreResults.isFetching ||
      maxPage.current === 1
    )
      return

    setLoading(false)
    setListings((currentListings) =>
      uniqueBySaleId([...(currentListings ?? []), ...moreResults.data])
    )
  }, [moreResults.isFetching, moreResults.isSuccess, moreResults.data])

  const setSort = ({ attr, dir }) => {
    const params = createSearchParams()
    let newSortAttr = attr
    let newSortDir = dir
    if (selectedSortAttr === attr && selectedSortDir === dir) {
      newSortAttr = ""
      newSortDir = ""
    }
    params.set("sort", newSortAttr)
    params.set("dir", newSortDir)

    resetListings()
    setSortAttr(newSortAttr)
    setSortDir(newSortDir)
    setSearchParams(params)
  }

  const loadPage = useCallback(
    (page) => {
      const { page: morePage, ...moreQuery } = moreResults.originalArgs ?? {}
      if (
        moreResults.isSuccess &&
        page === moreResults.originalArgs.page &&
        queriesEqual(salesQuery, moreQuery)
      ) {
        setListings((currentListings) =>
          uniqueBySaleId([...(currentListings ?? []), ...moreResults.data])
        )
        return
      }

      setLoading(true)
      trigger({ ...salesQuery, page }, true)
    },
    [salesQuery, trigger, moreResults]
  )
  const loadNextPage = () => {
    maxPage.current++
    loadPage(maxPage.current)
  }

  const MarketHeader = () => {
    const { data: { data } = { data: {} } } = useGetCollectionByNameQuery(
      collectionName,
      {
        skip: !collectionName,
      }
    )

    if (collectionName) {
      const displayName = data?.name || collectionName
      return (
        <>
          <Helmet>
            <title>{displayName}</title>
          </Helmet>
          <div className="px-4 py-5 text-center hero">
            <h1 className="display-6 fw-bold my-5">{displayName}</h1>
            <p>{data?.description}</p>
          </div>
        </>
      )
    }

    return (
      <>
        <Helmet>
          <title>Market</title>
        </Helmet>
        <div className="px-4 py-5 text-center hero">
          <h1 className="display-6 fw-bold my-5">Market</h1>
        </div>
      </>
    )
  }

  return (
    <>
      <MarketHeader />

      <div className="container py-5 my-5 px-5">
        <div className="row">
          <div className="col-sm">
            <h6 className="uppercase">Sort By:</h6>
            {}
            {sortOptions.map((sortOption) => {
              return (
                <React.Fragment key={sortOption.label}>
                  <SortButton
                    label={sortOption.label}
                    sortAttr={sortOption.attr}
                    sortDir="desc"
                    selectedSort={[selectedSortAttr, selectedSortDir]}
                    setSort={setSort}
                  />{" "}
                  <SortButton
                    label={sortOption.label}
                    sortAttr={sortOption.attr}
                    sortDir="asc"
                    selectedSort={[selectedSortAttr, selectedSortDir]}
                    setSort={setSort}
                  />{" "}
                </React.Fragment>
              )
            })}
          </div>
        </div>

        <div className="row mt-5">
          <hr />
        </div>

        <Listings isLoading={loading} error={error} listings={listings} />
        {!loading && (
          <div className="text-center my-5 py-5">
            <button
              className="btn btn-outline-dark btn-xs"
              onClick={() => {
                loadNextPage()
              }}
            >
              LOAD MORE...
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default Market
