import { Routes, Route } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import "../market.scss"
import MarketNav from "../MarketNav"
import Asset from "../features/asset/Asset"
import Home from "../Home"
import Footer from "../Footer"
import Sale from "../features/sale/Sale"
import Market from "../features/market/Market"
import Roadmap from "../Roadmap"
import About from "../About"
import Contact from "../Contact"
import FAQ from "../FAQ"
import Submission from "../Submission"
import TOS from "../TOS"
import Media from "../Media"
import NotFound from "../NotFound"
import Creator from "../Creator"
import Profile from "../features/profile/Profile"

function MarketRoutes() {
  return (
    <div id="market-app">
      <Helmet defaultTitle="Paraffin" titleTemplate="Paraffin - %s" />
      <MarketNav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="roadmap" element={<Roadmap />} />
        <Route path="contact" element={<Contact />} />
        <Route path="submission" element={<Submission />} />
        <Route path="media" element={<Media />} />
        <Route path="tos" element={<TOS />} />
        <Route path="creator" element={<Creator />} />
        <Route path="profile/:userName" element={<Profile />} />
        <Route path="market/:collectionName" element={<Market />} />
        <Route path="market" element={<Market />} />
        <Route path="sale/:saleId" element={<Sale />} />
        <Route path="asset/:assetId" element={<Asset />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  )
}

export default MarketRoutes
