const NotFound = () => {
  return (
    <div className="containermb-5 py-5 my-5">
      <div className="row">
        <div className="col mb-5 text-center">
          <img src="/img/404.gif" className="img-fluid my-5" alt="404 Error" />
          <h5>Oops... We couldn't find that.</h5>
        </div>
      </div>
    </div>
  )
}

export default NotFound
