import React, { useContext, useState } from "react"
import { Alert, Button, Spinner } from "react-bootstrap"
import { UALContext } from "ual-reactjs-renderer"

import {
  buyTransactions,
  confirmTransaction,
} from "../../app/services/chainActions"

const SaleState = ({ refetch, sale }) => {
  const { activeUser, showModal } = useContext(UALContext)
  const { sale_id } = sale
  const [buyState, setBuyState] = useState("ready")
  const [errorState, setErrorState] = useState()

  const alertClasses = "mt-5 p-2 alert-sale"

  const onMakePurcahse = async () => {
    setErrorState()
    if (activeUser) {
      setBuyState("Buying")
      const transaction = buyTransactions({
        buyer: activeUser,
        saleId: sale_id,
        assetIds: sale.assets.map((a) => a.asset_id),
        price: sale.price,
      })

      try {
        const result = await activeUser.signTransaction(transaction, {
          broadcast: true,
          blocksBehind: 3,
          expireSeconds: 30,
        })

        const transactionId = result.transactionId
        setBuyState("Confirming")
        const confirmed = await confirmTransaction({
          rpc: activeUser.rpc,
          transactionId,
        })
        if (confirmed) {
          setBuyState("Purchased")
          refetch()
        } else {
          setErrorState(
            "We could not automatically confirm your transaction. Try manually refreshing the page"
          )
        }
      } catch (e) {
        setErrorState(e.message)
        setBuyState("ready")
        console.log(e.type, e.cause)
      }
    } else {
      showModal()
    }
  }

  const BuyButton = () => {
    const Error = () => {
      return (
        <Alert
          show={!!errorState}
          variant="danger"
          dismissible
          onClose={() => setErrorState()}
        >
          {errorState}
        </Alert>
      )
    }

    const ButtonText = () => {
      if (buyState === "ready") {
        return "BUY NOW"
      } else {
        return (
          <>
            <Spinner animation="border" size="sm" /> {buyState}...
          </>
        )
      }
    }

    return (
      <>
        <Error />
        <Button
          variant="dark"
          size="lg"
          className="mt-4 px-4"
          disabled={buyState !== "ready"}
          onClick={onMakePurcahse}
        >
          <ButtonText />
        </Button>
      </>
    )
  }

  const CanceledBanner = () => {
    return (
      <Alert variant="danger" className={alertClasses}>
        This listing has been canceled by the seller.
      </Alert>
    )
  }

  const SoldBanner = () => {
    const date = new Date(parseInt(sale.updated_at_time))

    const at = date
      ? " " +
        date.toLocaleDateString("en-US", {
          month: "numeric",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
        })
      : ""

    return (
      <Alert variant="success" className={alertClasses}>
        Purchased {at} by: <span>{sale.buyer}</span>
      </Alert>
    )
  }

  const InvalidBanner = () => {
    return (
      <Alert variant="danger" className={alertClasses}>
        Listing is not valid
      </Alert>
    )
  }

  switch (sale.state) {
    case 1:
      return <BuyButton />
    case 2:
      return <CanceledBanner />
    case 3:
      return <SoldBanner />
    default:
      return <InvalidBanner />
  }
}

export default SaleState
