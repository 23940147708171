import AssetImage from "../../app/AssetImage"
import AssetSkeletons from "../../app/AssetSkeletons"

const Assets = ({ isLoading, error, assets = [] }) => {
  if (error) {
    return (
      <div className="text-center my-5 py-5"> Error Loading Inventory...</div>
    )
  }
  return (
    <div className="row my-5">
      {assets.map((asset) => {
        return (
          <div className="col-lg-4 col-md-6 mb-4" key={asset.asset_id}>
            <div className="list-item border p-3">
              <AssetImage
                asset={asset}
                className={"img-fluid mx-auto d-block"}
              />

              <div className="row pt-3">
                <div className="col text-truncate">
                  <h4>{asset.collection?.collection_name}</h4>
                  <p>{asset.name}</p>
                </div>
              </div>
            </div>
          </div>
        )
      })}

      {isLoading ? <AssetSkeletons count={99} includePrice={false} /> : null}
    </div>
  )
}

export default Assets
