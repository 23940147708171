import { configureStore } from "@reduxjs/toolkit"
import { reducer as profileReducer } from "../features/profile/profileSlice"

import { assetsApi } from "./services/assetsApi"
import { marketApi } from "./services/marketApi"

export default configureStore({
  reducer: {
    profile: profileReducer,
    [assetsApi.reducerPath]: assetsApi.reducer,
    [marketApi.reducerPath]: marketApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(assetsApi.middleware, marketApi.middleware),
})
