import { createApi } from "@reduxjs/toolkit/query/react"
import { ExplorerApi } from "atomicassets"
import getWaxEnv from "../getWaxEnv"

const assetApis = {}

const getAssetsApi = () => {
  const waxEnv = getWaxEnv()

  if (!assetApis.hasOwnProperty(waxEnv.env)) {
    assetApis[waxEnv.env] = new ExplorerApi(
      waxEnv.atomicassetsUrl,
      "atomicassets",
      { fetch }
    )
  }

  return assetApis[waxEnv.env]
}

export const assetsApi = createApi({
  reducerPath: "assetsApi",
  baseQuery: async ({ method, args }, api, extraOptions) => {
    try {
      const response = await getAssetsApi()[method](...args)
      return { data: response }
    } catch (e) {
      return { error: { message: e.message, status: e.status } }
    }
  },
  endpoints: (build) => ({
    getAsset: build.query({
      query: (assetId) => ({
        method: "getAsset",
        args: [assetId],
      }),
    }),
    getCollectionByName: build.query({
      query: (collectionName) => ({
        method: "getCollection",
        args: [collectionName],
      }),
    }),
  }),
})

export const { useGetAssetQuery, useGetCollectionByNameQuery } = assetsApi
