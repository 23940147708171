import React from "react"
import SaleState from "./SaleState"
import { ogImageUrl } from "../../app/AssetImage"
import AssetDisplay from "../asset/AssetDisplay"
import salePrice from "./salePrice"
import { Helmet } from "react-helmet-async"

const Single = ({ refetch, sale }) => {
  const asset = sale.assets[0]

  const SaleInfo = () => {
    return (
      <>
        <h4 className="mt-3">PRICE</h4>
        <p>{salePrice(sale)}</p>
        <SaleState refetch={refetch} sale={sale} />
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>{asset.name || "Untitled"} Sale</title>
        <meta property="og:image" content={ogImageUrl(asset)} />
        <meta
          property="og:title"
          content={`${asset.name || "Untitled"} Sale`}
        />
      </Helmet>
      <AssetDisplay asset={asset} SaleInfo={SaleInfo} />
    </>
  )
}

export default Single
