import { Helmet } from "react-helmet-async"

const Submission = () => {
  return (
    <>
      <Helmet title="Submission" />
      <div className="container mt-5 mb-5 pb-5 content-static">
        <div className="row pt-5 mt-5">
          <div className="col mb-5">
            <h2>Whitelist Submissions</h2>
            <p>
              It is our goal to curate and promote the best art and indie NFTs
              on the WAX Blockchain. We know that amazing tallent does not
              necessarily command the attention that it deserves. Thus, we've
              created the form below to collect the works that are flying under
              the radar.
            </p>
            <p>
              If you would like your work to be featured in our marketplace, or
              if you know of artwork minted on WAX that you think shoud be
              featured, please let us know about it by submitting a link to the
              collection below.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light mt-5 mt-5 pt-5 text-center">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSccMSPi1aBHiq8OXnvh4nv57tkJ9RCkt-B1KT1FX1WRWNTx-Q/viewform?embedded=true"
          width="100%"
          height="1200"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          title="submission-form"
        >
          Loading…
        </iframe>
      </div>
    </>
  )
}

export default Submission
