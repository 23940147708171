import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import ScrollToTop from "./app/ScrollToTop"
import "react-loading-skeleton/dist/skeleton.css"

import MarketApp from "./market/App"
import PlaylistApp from "./playlist/App"

function App() {
  return (
    <>
      <Helmet defaultTitle="Paraffin" titleTemplate="Paraffin - %s" />
      <Router>
        <ScrollToTop />

        <Routes>
          <Route path="/playlist/*" element={<PlaylistApp />} />
          <Route path="*" element={<MarketApp />} />
        </Routes>
      </Router>
    </>
  )
}

export default App
