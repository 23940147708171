import Skeleton from "react-loading-skeleton"

const AssetSkelton = ({ includePrice }) => {
  return (
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="list-item border p-3">
        <Skeleton height={"300px"} />
        <div className="row pt-3">
          <div className="col">
            <div className="row">
              <Skeleton width={"40%"} />
            </div>
            <div className="row">
              <Skeleton width={"90%"} />
            </div>
          </div>
          <div className="col">
            <div className="row text-end">
              <Skeleton width={"40%"} />
            </div>
            {includePrice && (
              <div className="row text-end">
                <Skeleton width={"60%"} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const AssetSkeletons = ({ count, includePrice = false }) => {
  return Array.from({ length: count }).map((_, index) => (
    <AssetSkelton key={index} includePrice={includePrice} />
  ))
}

export default AssetSkeletons
