const uploadEndpoint = "https://ipfs-gateway.pink.gg/v1/upload"

const ipfsUpload = async (file) => {
  const [fileType, fileSubtype] = file.type.split("/")
  if (fileType !== "image") {
    return { error: "Only images are supported" }
  }
  if (
    fileSubtype !== "png" &&
    fileSubtype !== "jpeg" &&
    fileSubtype !== "jpg" &&
    fileSubtype !== "gif"
  ) {
    return { error: "Only png, jpeg, jpg, and gif are supported" }
  }

  const formData = new FormData()
  formData.append("image", file)
  const ipfsReponse = await fetch(uploadEndpoint, {
    method: "PUT",
    body: formData,
  })

  if (ipfsReponse.ok) {
    const responseJson = await ipfsReponse.json()
    return { data: responseJson.data }
  }
  return { error: "Error uploading to IPFS" }
}

export default ipfsUpload
