import { Helmet } from "react-helmet-async"

const Contact = () => {
  return (
    <>
      <Helmet title="Contact" />
      <div className="container mt-5 mb-5 pb-5 content-static contact-form">
        <div className="row pt-5 mt-5">
          <div className="col mb-5">
            <h2>Contact</h2>
            <p>
              DM us on Twitter{" "}
              <a
                href="https://twitter.com/paraffinNFT"
                target="_blank"
                rel="noreferrer"
              >
                @ParaffinNFT
              </a>{" "}
              or complete the form below. We'll get back to you as soon as
              possible.
            </p>
            <iframe
              src="https://us1.list-manage.com/contact-form?u=262db2ac3d5ef879f9e529621&form_id=5766f3407cd1262c49e398ad18b77944"
              title="Contact Form"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact
