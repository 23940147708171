import { useState } from "react"
import MailchimpSubscribe from "react-mailchimp-subscribe"

const SubscribeForm = ({ subscribe, status, message }) => {
  const [email, setEmail] = useState("")

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleSubmit = ({ e, subscribe }) => {
    e.preventDefault()
    subscribe({
      EMAIL: email,
    })
  }

  if (message) message = message.replace(/^0 - /, "")

  return (
    <>
      {status === "sending" && <div className="mailchimp">Joining...</div>}
      {status === "error" && (
        <div
          className="col-sm-12 mailchimp"
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          className="col-sm-12 mailchimp"
          style={{ color: "green" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <div className="col-sm-12 mailchimp">
        <form
          className="input-group mb-3"
          onSubmit={(e) => {
            handleSubmit({ e, subscribe })
          }}
        >
          <input
            type="text"
            className="form-control"
            placeholder="email@example.com"
            aria-label="email address"
            aria-describedby="basic-addon2"
            onChange={handleEmailChange}
          />
          <input
            type="submit"
            className="input-group-text"
            id="basic-addon2"
            value="JOIN"
          ></input>
        </form>
      </div>
    </>
  )
}

const JoinMailingList = () => {
  return (
    <MailchimpSubscribe
      url={
        "https://paraffin.us1.list-manage.com/subscribe/post?u=262db2ac3d5ef879f9e529621&id=58ff4d76cb"
      }
      render={({ subscribe, status, message }) => (
        <SubscribeForm
          status={status}
          message={message}
          subscribe={subscribe}
        />
      )}
    />
  )
}

export default JoinMailingList
