import { useState } from "react"
import Skeleton from "react-loading-skeleton"

const ipfsHosts = [
  "ipfs.hivebp.io",
  "cloudflare-ipfs.com",
  "hub.textile.io",
  "gateway.ipfs.io",
  "ipfs.io",
]
const ipfsHost = ipfsHosts[0]

const thumbnailHeight = "300"

const wrapUrl = ({ url, thumbnail = false }) => {
  return `https://images.weserv.nl/?url=${encodeURIComponent(url)}&il&af${
    thumbnail ? `&h=${thumbnailHeight}&n=-1&we` : ""
  }`
}

const getUrls = ({ src, thumbnail = false }) => {
  const alreadyUrl = src.match(/^https:\/\/(.*)/)?.[1]

  if (alreadyUrl) {
    return [wrapUrl({ url: alreadyUrl, thumbnail })]
  }

  const urls = ipfsHosts.map((host) => `https://${host}/ipfs/${src}`)

  if (thumbnail) {
    return urls.map((url) => wrapUrl({ url, thumbnail })).concat(urls)
  }

  return urls
}

const ogImageUrl = ({ asset }) => {
  const media = findMedia(asset)
  if (!media) {
    return null
  }
  return getUrls({ src: media.src })[0]
}

const ImageWithFallback = ({
  urls,
  alt,
  thumbnail,
  className = "",
  ...props
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [urlNum, setUrlNum] = useState(0)
  const url = urls[urlNum]

  const onError = () => {
    if (urlNum < urls.length - 1) {
      setUrlNum(urlNum + 1)
    }
  }

  const height = thumbnail ? "250px" : "400px"

  const onLoaded = () => {
    setIsLoaded(true)
  }

  if (!isLoaded) {
    className = className.replace("d-block", "")
    className = className + " d-none"
  }

  return (
    <>
      {!isLoaded && <Skeleton height={height} />}
      <img
        {...props}
        className={className}
        src={url}
        alt={alt}
        onError={onError}
        onLoad={onLoaded}
      />
    </>
  )
}

const AssetImage = ({ asset, className, thumbnail = false }) => {
  const media = findMedia(asset)
  const src = media?.src
  if (!media) {
    return (
      <img
        src={"/img/media_not_available.png"}
        className={className}
        alt={"Not Found"}
      />
    )
  }

  if (media.type === "image") {
    const media_urls = getUrls({ src, thumbnail })
    return (
      <ImageWithFallback
        urls={media_urls}
        className={className}
        thumbnail={thumbnail}
        alt={asset.name || "Untitled NFT Image"}
      />
    )
  }

  if (media.type === "video") {
    const media_urls = getUrls({ src })
    return (
      <video
        className={className}
        autoPlay
        loop
        playsInline
        muted
        poster={"/img/video_loading.png"}
        alt={asset.name || "Untitled NFT Video"}
      >
        {media_urls.map((src) => (
          <source src={src} key={src} />
        ))}
      </video>
    )
  }
}

const findMedia = (asset = {}) => {
  const { data, schema } = asset
  if (!data) return null
  if (data.img) {
    return {
      type: "image",
      src: data.img,
    }
  }
  if (data.video) {
    return {
      type: "video",
      src: data.video,
    }
  }
  if (schema) {
    let image_attrs = schema.format
      .filter(function (attrs) {
        return "image" === attrs.type
      })
      .filter(function (attr) {
        return !!data[attr.name]
      })
      .map(function (attr) {
        return attr.name
      })
    if (image_attrs.length > 0)
      return {
        type: "image",
        src: data[image_attrs[0]],
      }
  }
}

export default AssetImage
export { ipfsHost, ogImageUrl, ImageWithFallback, getUrls }
