import { Link } from "react-router-dom"
import { Helmet } from "react-helmet-async"

const About = () => {
  return (
    <>
      <Helmet title="About" />
      <div className="container mt-5 mb-5 pb-5 content-static">
        <div className="row pt-5 mt-5">
          <div className="col mb-5">
            <h2>About</h2>
            <p>
              Paraffin was founded with the goal of bringing attention to lesser
              known fine art and indie NFT projects on the blockchain.
            </p>

            <p>
              As such, we built our platform on top of the purpose-built WAX
              Blockchain—a technology stack that is intentionally designed, and
              uniquely suited, for minting, buying, selling, trading, and
              collecting NFTs (non fungible tokens).
            </p>

            <p>
              Features including free NFT Creation, near zero transaction fees,
              and a shallow learning curve make WAX the ideal solution for
              artists and creatives who want to focus on their craft, not
              complicated crypto wallets, exorbitant gas fees, and a learning
              curve so steep that it requires dual degrees in computer science
              and macroeconomics.
            </p>

            <h3>How does Paraffin work?</h3>

            <p>
              Paraffin leverages the{" "}
              <a
                href="https://github.com/pinknetworkx/atomicmarket-contract"
                target="_blank"
                rel="noreferrer"
              >
                atomic assets market contract
              </a>{" "}
              "a shared liquidity NFT market smart contract which is used by
              multiple websites… Shared liquidity means that everything which is
              listed on one market also shows on all other markets.” As such,
              any asset that is listed on AtomicHub, NFTHive, WAXStache, etc
              could be purchased on paraffin simply by passing the sale ID to
              the end of the url paraffin.io/sale/.
            </p>

            <p>
              Our focus, however, is not to be just another marketplace for
              digital trading cards. Instead, we are focused on empowering
              artists and indie NFT projects by curating the best projects on
              WAX and shining a spotlight on it.
            </p>

            <p>
              Our market listings don’t populate based solely on algorithms.
              Instead, humans review collection submissions and whitelist them
              onto our platform. This enables us to surface work that would
              otherwise be buried amongst the millions of NFTs on other
              marketplaces.
            </p>

            <p>
              If you’re an artist or indie NFT project that would like their
              work listed on our marketplace, or know of art on WAX that
              deserves more attention, consider making a{" "}
              <Link to="/submission" title="Whitelist Submission">
                submission
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
