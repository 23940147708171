import { Routes, Route } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import "./playlist.scss"
import Nav from "./Nav"
import Home from "./Home"
import Footer from "../Footer"
import Builder from "./Builder"
import Playlists from "./Playlists"
import NotFound from "../NotFound"
import Asset from "../features/asset/Asset"

function App() {
  return (
    <div id="playlist-app">
      <Helmet
        defaultTitle="Paraffin Playlist"
        titleTemplate="Paraffin Playlist - %s"
      />
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="builder" element={<Builder />} />
        <Route path="playlists" element={<Playlists />} />
        <Route path="asset/:assetId" element={<Asset />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  )
}

export default App
