const getWaxConfig = () => {
  const waxEnv = localStorage.getItem("wax-env") === "dev" ? "dev" : "prod"

  if (waxEnv === "dev") {
    return {
      atomicassetsUrl: "https://test.wax.api.atomicassets.io",
      chainId:
        "f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12",
      chainName: "WAX Testnet",
      env: waxEnv,
      rpcProtocol: "https",
      rpcHost: "test.wax.eosusa.news",
      rpcPort: "443",
    }
  } else {
    return {
      atomicassetsUrl: "https://atomic.hivebp.io",
      chainId:
        "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
      chainName: "WAX Mainnet",
      env: waxEnv,
      rpcProtocol: "https",
      rpcHost: "wax.greymass.com",
      rpcPort: "443",
    }
  }
}

export default getWaxConfig
