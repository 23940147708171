import React from "react"
import { Link } from "react-router-dom"
import AssetImage from "../../app/AssetImage"
import Play from "./Play"

const AssetDisplay = ({ asset, SaleInfo }) => {
  const collection = asset.collection
  const template = asset.template
  let max_supply = template?.max_supply ? template.max_supply : "N/A"
  if (max_supply === "0") max_supply = "∞"

  const immutable_data = {
    ...template?.immutable_data,
    ...asset.immutable_data,
  }

  if (asset.collection.collection_name === "paraffinplst")
    return <Play asset={asset} />

  return (
    <div className="container py-5">
      <div className="row py-5">
        <div className="col-md-8 col-lg-6 mb-5">
          <AssetImage
            asset={asset}
            className={"img-fluid shadow mx-auto d-block sale-img"}
          />
        </div>

        <div className="col-md-4 col-lg-3 order-md-first mb-5">
          <h3 className="mb-5">{asset.name || "Untitled"}</h3>
          <h4>MINT NO.</h4>
          <p>
            {asset.template_mint || "?"} of {template?.issued_supply} (max:{" "}
            {max_supply})
          </p>
          <h4 className="mt-3">COLLECTION NAME</h4>
          <p>
            <Link to={`/market/${collection.collection_name}`}>
              {collection.name}
            </Link>
          </p>
          <h4 className="mt-3">TEMPLATE ID:</h4>
          <p>#{template?.template_id}</p>
          {SaleInfo && <SaleInfo />}
        </div>

        <div className="col-md-12 col-lg-3 text-start text-break immutable-attributes">
          <h4 className="mb-3">IMMUTABLE ATTRIBUTES</h4>
          {Object.keys(immutable_data).map((key) => {
            return (
              <React.Fragment key={key}>
                <p className="heavy mb-0">{key}</p>
                <p>{immutable_data[key]}</p>
              </React.Fragment>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default AssetDisplay
