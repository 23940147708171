import { Link } from "react-router-dom"
import JoinMailingList from "./features/joinMailingList"

const Footer = () => {
  return (
    <div className="footer">
      <div className="container py-5 text-light">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <h2 className="py-5">Join Our Mailing List</h2>
          </div>

          <div className="col-md-6 justify-content-center text-center">
            <JoinMailingList />
          </div>
        </div>

        <div className="row py-5">
          <hr className="mb-5" />
          <div className="col-md-2 col-lg-3">
            <Link to="/" title="Paraffin.io">
              <h3>PARAFFIN</h3>
              <p>© 2022 PARAFFIN.io</p>
            </Link>
          </div>

          <div className="col-md-2 col-lg-3">
            <h4 className="mb-2">FIND US ONLINE</h4>
            <ul className="list-unstyled link-light">
              <li>
                <a
                  href="https://twitter.com/paraffinNFT"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter
                </a>
              </li>
              <li>
                <a
                  href="https://instagram.com/paraffinNFT"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  href="https://medium.com/@paraffinNFT"
                  target="_blank"
                  rel="noreferrer"
                >
                  Medium
                </a>
              </li>
            </ul>
          </div>

          <div className="col-md-2 col-lg-3">
            <h4 className="mb-2">PARAFFIN PLAYLISTS</h4>
            <ul className="list-unstyled link-light">
              <li>
                <Link to="/playlist" title="Comiong Soon...">
                  Playlists Home
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-md-2 col-lg-3">
            <h4 className="mb-2">PARAFFIN MARKET</h4>
            <ul className="list-unstyled link-light">
              <li>
                <Link to="/about" title="About">
                  About
                </Link>
              </li>
              <li>
                <Link to="/faq" title="Frequently Asked Questions">
                  FAQ
                </Link>
              </li>
              <li>
                <Link to="/submission" title="Submissions">
                  Whitelist Templates
                </Link>
              </li>
              <li>
                <Link to="/contact" title="Media Inquiries">
                  Media Inqueries
                </Link>
              </li>
              <li>
                <Link to="/tos" title="Terms of Service">
                  Terms of Service
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
