import { Container, Col, Row, Button, Modal } from "react-bootstrap"
import React, { useState } from "react"

const Playlists = () => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <>
      <div className="px-5 py-5 text-light bg-salmon">
        <h1 className="display-4 mb-5 text-center text-md-start">Playlists</h1>
      </div>

      <Container className="my-5">
        <Row classname=" text-center">
          <Col md="3" sm="6" className="my-3">
            <a href="play">
              <img
                src="../img/playlist/human-music.gif"
                className="img-fluid cover-art"
                alt="Placeholder"
              />
            </a>
            <div className="py-2 text-end">
              <i
                className="bi bi-arrow-left-right pointer"
                onClick={handleShow}
              ></i>
              <a
                href="https://open.spotify.com/playlist/31QySbrTSYRN6cxoVfOp9m?si=6671426000a242e0"
                target="_blank"
                className="spotify"
                rel="noreferrer"
              >
                <i
                  className="bi bi-spotify"
                  alt="Listen on Spotify"
                  title="Listen on Spotify"
                ></i>
              </a>
            </div>
          </Col>
          <Col md="3" sm="6" className="my-3">
            <img
              src="../img/playlist/blue.jpg"
              className="img-fluid cover-art"
              alt="Placeholder"
            />
            <div className="py-2 text-end">
              <i
                className="bi bi-arrow-left-right pointer"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              ></i>
              <a
                href="https://open.spotify.com/playlist/31QySbrTSYRN6cxoVfOp9m?si=6671426000a242e0"
                target="_blank"
                className="spotify"
                rel="noreferrer"
              >
                <i
                  className="bi bi-spotify"
                  alt="Listen on Spotify"
                  title="Listen on Spotify"
                ></i>
              </a>
            </div>
          </Col>
          <Col md="3" sm="6" className="my-3">
            <img
              src="../img/playlist/happy-new-year.jpg"
              className="img-fluid cover-art"
              alt="Placeholder"
            />
            <div className="py-2 text-end">
              <i
                className="bi bi-arrow-left-right pointer"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              ></i>
              <a
                href="https://youtube.com"
                target="_blank"
                className="youtube"
                rel="noreferrer"
              >
                <i
                  className="bi bi-youtube"
                  alt="Watch on YouTube"
                  title="Watch on YouTube"
                ></i>
              </a>
            </div>
          </Col>
          <Col md="3" sm="6" className="my-3">
            <img
              src="../img/playlist/north-woods.jpg"
              className="img-fluid cover-art"
              alt="Placeholder"
            />
            <div className="py-2 text-end">
              <i
                className="bi bi-arrow-left-right pointer"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              ></i>
              <a
                href="https://open.spotify.com/playlist/31QySbrTSYRN6cxoVfOp9m?si=6671426000a242e0"
                target="_blank"
                className="spotify"
                rel="noreferrer"
              >
                <i
                  className="bi bi-spotify"
                  alt="Listen on Spotify"
                  title="Listen on Spotify"
                ></i>
              </a>
            </div>
          </Col>

          <Col md="3" sm="6" className="my-3">
            <img
              src="../img/playlist/yacht-rock.jpg"
              className="img-fluid cover-art"
              alt="Placeholder"
            />
            <div className="py-2 text-end">
              <i
                className="bi bi-arrow-left-right pointer"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              ></i>
              <a
                href="https://open.spotify.com/playlist/31QySbrTSYRN6cxoVfOp9m?si=6671426000a242e0"
                target="_blank"
                className="spotify"
                rel="noreferrer"
              >
                <i
                  className="bi bi-spotify"
                  alt="Listen on Spotify"
                  title="Listen on Spotify"
                ></i>
              </a>
            </div>
          </Col>
          <Col md="3" sm="6" className="my-3">
            <img
              src="../img/playlist/holiday-mix.gif"
              className="img-fluid cover-art"
              alt="Placeholder"
            />
            <div className="py-2 text-end">
              <i
                className="bi bi-arrow-left-right pointer"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              ></i>
              <a
                href="https://open.spotify.com/playlist/31QySbrTSYRN6cxoVfOp9m?si=6671426000a242e0"
                target="_blank"
                className="spotify"
                rel="noreferrer"
              >
                <i
                  className="bi bi-spotify"
                  alt="Listen on Spotify"
                  title="Listen on Spotify"
                ></i>
              </a>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Human Music</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src="../img/playlist/human-music.gif"
            className="img-fluid cover-art"
            alt="Placeholder"
          />
          <div className="row my-3">
            <label for="inputPlaylistTitle" className="col-sm-2 col-form-label">
              To:
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                placeholder="wax account"
              />
            </div>
          </div>
          <div className="row mb-3">
            <label for="inputPlaylistTitle" className="col-sm-2 col-form-label">
              Memo:
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                placeholder="optional"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-accent" onClick={handleClose}>
            Send Transssfer
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <!-- OLD MODAL --> */}
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Human Music
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-center">
              <img
                src="../img/playlist/human-music.gif"
                className="img-fluid cover-art"
                alt="Placeholder"
              />
              <div className="row my-3">
                <label
                  for="inputPlaylistTitle"
                  className="col-sm-2 col-form-label"
                >
                  To:
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="wax account"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label
                  for="inputPlaylistTitle"
                  className="col-sm-2 col-form-label"
                >
                  Memo:
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="optional"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary">
                Send Transfer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Playlists
