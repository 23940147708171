import { Link } from "react-router-dom"

import { useGetFeaturedSalesQuery } from "./app/services/marketApi"
import Listings from "./features/market/Listings"

const featuredSales = [
  26547174, 27252427, 27250747, 27244466, 27246680, 27248517, 7248845, 27250563,
  27247945, 27251388, 25488492, 9688231, 26802311, 26801810, 26453871, 6270240,
  26273276, 26250691, 26176012, 25953710, 26025507, 25957511, 25954926,
  25989943, 24521308, 23311970, 25824480, 25827168, 25828700, 25830109,
  25831218, 25816222, 25730695, 23365443, 25652198, 24056462, 24875285,
  24977925, 23124483, 24585887, 24758785, 24917482, 24777179, 24650164,
  13842629, 9159276, 17968747, 24493088, 24443494, 24482937, 24129302, 24705287,
  13118259, 19611043, 13842547, 13840968, 14978801, 4922209, 13841084,
]
const featuredLimit = 12
const featuredSalesOrderMap = {}
featuredSales.forEach(
  (sale, index) => (featuredSalesOrderMap[sale.toString()] = index)
)

const sortListingsByfeaturedSalesList = (data) =>
  [...data]
    .sort(
      (a, b) =>
        featuredSalesOrderMap[a.sale_id] - featuredSalesOrderMap[b.sale_id]
    )
    .slice(0, featuredLimit)

const Home = () => {
  const { isLoading, error, data } = useGetFeaturedSalesQuery(featuredSales)
  const listings = data && sortListingsByfeaturedSalesList(data)

  return (
    <>
      <div>
        <div className="px-4 py-5 text-center hero">
          <h1 className="display-1 fw-bold my-5">
            Discover More <br />
            on WAX
          </h1>
          <div className="col-lg-6 mx-auto">
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
              <Link to="/market" title="Market">
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-lg mb-5 px-4 btn-light"
                >
                  BROWSE THE MARKET
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5 my-5">
        <div className="row text-center">
          <h2>Featured</h2>
        </div>
        <div className="row my-5">
          <Listings isLoading={isLoading} error={error} listings={listings} />
        </div>
      </div>

      <div className="container hero-below">
        <div className="row my-5 py-2">
          <div className="col-md pb-3 text-center">
            <h1 className="py-5">Where To Start</h1>
          </div>
          <div className="col-md pb-3 text-center">
            <i className="bi bi-wallet2"></i>
            <h4 className="uppercase">Set Up a Wallet</h4>
            <p>
              The pieces featured on this market are traded on the WAX
              blockchain. Visit{" "}
              <a href="https://wallet.wax.io" target="_blank" rel="noreferrer">
                wax.wallet.io
              </a>{" "}
              to set up a crypto wallet in just two clicks so you can buy WAX
              and hold NFTs.
            </p>
          </div>
          <div className="col-md pb-3 text-center">
            <i className="bi bi-search"></i>
            <h4 className="uppercase">Discover Art You Love</h4>
            <p>
              Most WAX marketplaces are dominated by corporate projects. We're
              scouring the blockchain to currate the best crypto art and indie
              NFTS on WAX.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
