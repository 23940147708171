import { Container, Button, Modal } from "react-bootstrap"
import React, { useState } from "react"
import AssetImage from "../../app/AssetImage"
import "./play.scss"

const Play = ({ asset }) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  return (
    <>
      <div className="px-5 py-5 text-light bg-salmon">
        <h1 className="display-4 mb-5 text-center text-md-start">Playlists</h1>
      </div>

      <Container className="my-5">
        <div className="row text-center">
          <div className="col-md-4 my-3">
            <AssetImage
              asset={asset}
              className={"img-fluid shadow mx-auto d-block sale-img"}
            />
            <div className="py-2 text-end">
              <i
                className="bi bi-arrow-left-right pointer"
                // onClick={handleShow}
              ></i>
              <a
                href={asset.immutable_data.link}
                target="_blank"
                className="spotify"
                rel="noreferrer"
              >
                <i
                  className="bi bi-spotify"
                  alt="Listen on Spotify"
                  title="Listen on Spotify"
                ></i>
              </a>
            </div>
          </div>
          <div className="col-md-8 my-3 bg-light p-0 player">
            <div className="loading">
              <div className="spinner-grow mx-2" role="status"></div>
              <div className="spinner-grow mx-2" role="status"></div>
              <div className="spinner-grow mx-2" role="status"></div>
            </div>
            <div className="embed px-4 px-md-0">
              <iframe
                title="playlist"
                src={asset.immutable_data.link}
                width="100%"
                height="100%"
                frameBorder="0"
                allowfullscreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              ></iframe>
            </div>
          </div>
        </div>
      </Container>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Human Music</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src="../img/playlist/human-music.gif"
            className="img-fluid cover-art"
            alt="Placeholder"
          />
          <div className="row my-3">
            <label for="inputPlaylistTitle" className="col-sm-2 col-form-label">
              To:
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                placeholder="wax account"
              />
            </div>
          </div>
          <div className="row mb-3">
            <label for="inputPlaylistTitle" className="col-sm-2 col-form-label">
              Memo:
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                placeholder="optional"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-accent" onClick={handleClose}>
            Send Transssfer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Play
