import { useContext, useState } from "react"
import {
  Alert,
  Button,
  Container,
  Col,
  Form,
  Row,
  Spinner,
} from "react-bootstrap"
import Skeleton from "react-loading-skeleton"
import { UALContext } from "ual-reactjs-renderer"
import { useNavigate } from "react-router-dom"
import { getUrls, ImageWithFallback } from "../app/AssetImage"
import ipfsUpload from "../app/services/ipfsUpload"
import mintPlaylist from "./builder/mintPlaylist"

const Cover = ({ imageHash, uploading }) => {
  if (imageHash) {
    return (
      <ImageWithFallback
        urls={getUrls({ src: imageHash, thumbnail: true })}
        thumbnail={true}
        className="img-fluid cover-art"
      />
    )
  }
  if (uploading) {
    return <Skeleton height={"250px"} />
  }
  return null
}

const Builder = () => {
  const { activeUser, showModal } = useContext(UALContext)
  const [uploadingCover, setUploadingCover] = useState(false)
  const [coverHash, setCoverHash] = useState()
  const [validated, setValidated] = useState(false)
  const [mintState, setMintState] = useState("ready")
  const [errorState, setErrorState] = useState()
  const navigate = useNavigate()

  const MintButton = () => {
    const Error = () => {
      return (
        <Alert
          show={!!errorState}
          variant="danger"
          dismissible
          onClose={() => setErrorState()}
        >
          {errorState}
        </Alert>
      )
    }

    const ButtonText = () => {
      if (mintState === "ready") {
        return "Mint Playlist (3 WAX)"
      } else {
        return (
          <>
            <Spinner animation="border" size="sm" /> {mintState}...
          </>
        )
      }
    }

    return (
      <>
        <Error />
        <Button
          type="submit"
          className="float-end"
          disabled={mintState !== "ready"}
        >
          <ButtonText />
        </Button>
      </>
    )
  }

  const handleFileChange = async (e) => {
    setUploadingCover(true)
    const file = e.target.files[0]

    if (typeof file == "undefined") {
      setUploadingCover(false)
      return
    }

    const uploadResponse = await ipfsUpload(file)
    if (uploadResponse.error) {
      console.log(uploadResponse.error)
      e.preventDefault()
      e.target.value = ""
      setUploadingCover(false)
      return
    }

    setCoverHash(uploadResponse.data)
    setUploadingCover(false)
  }

  const handleSubmit = async (e) => {
    setErrorState()
    e.preventDefault()
    if (!activeUser) {
      showModal()
      return
    }

    const form = e.currentTarget
    const formData = new FormData(form)
    const data = Object.fromEntries(formData.entries())
    data["cover_art_image"] = coverHash

    setValidated(true)
    if (form.checkValidity() === true) {
      setMintState("Minting...")
      const result = await mintPlaylist(activeUser, data)
      if (result.status === "success") {
        const assetId = result.assetId
        navigate(`../asset/${assetId}`)
      } else {
        setErrorState(result.message)
        if (result.status === "error") {
          setMintState("ready")
        }
      }
    }
  }

  return (
    <>
      <div className="px-5 py-5 text-light bg-salmon">
        <h1 className="display-4 mb-5 text-center text-md-start">
          Playlist Builder
        </h1>
      </div>

      <Container className="my-5">
        <Row>
          <h2>Builder</h2>
        </Row>
        <Form noValidate onSubmit={handleSubmit} validated={validated}>
          <Row className="mb-5">
            <Col md="4" className="text-center">
              <Form.Group controlId="upload-art">
                <Cover imageHash={coverHash} uploading={uploadingCover} />
                <Form.Label>Upload Cover Art</Form.Label>
                <Form.Control
                  required
                  type="file"
                  size="sm"
                  onChange={handleFileChange}
                  isInvalid={!coverHash && !uploadingCover && validated}
                />
              </Form.Group>
            </Col>
            <Col md="8">
              <Form.Group as={Row} controlId="title" className="mb-3">
                <Form.Label column sm="2">
                  Playlist Title:
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    name="title"
                    required
                    placeholder="My Playlist"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="link" className="mb-3">
                <Form.Label column sm="2">
                  Playlist Link:
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    required
                    name="link"
                    placeholder="Spotify, YouTube, SoundCloud..."
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="message" className="mb-3">
                <Form.Label column sm="2">
                  Message:
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    as="textarea"
                    className="message"
                    name="message"
                    placeholder="Leave a message here..."
                  />
                </Col>
              </Form.Group>
              <MintButton />
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  )
}

export default Builder
