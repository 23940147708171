import { createSlice } from "@reduxjs/toolkit"
import { fetchInventory } from "./profileThunk"

// The initial state of the Sale component
export const initialState = {
  inventory: [],
  loading: true,
  error: "lkg",
}

const profileSlice = createSlice({
  name: "profile/inventory",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchInventory.pending]: (state) => {
      state.loading = true
      state.error = false
      state.inventory = []
    },
    [fetchInventory.fulfilled]: (state, action) => {
      state.loading = false
      state.inventory = action.payload
    },
    [fetchInventory.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error
      state.inventory = []
    },
  },
})

export const { name, actions, reducer } = profileSlice
