import { useParams } from "react-router-dom"
import { useGetSaleQuery } from "../../app/services/marketApi"
import Single from "./Single"
import Multi from "./Multi"

import "./Sale.css"

const NotFound = () => {
  return <div className="text-center my-5 py-5">No sale found</div>
}

const Sale = () => {
  const { saleId } = useParams()
  const pollingInterval = 10 * 1000 // 10 seconds

  const {
    isLoading: loading,
    error,
    data: sale,
    refetch,
  } = useGetSaleQuery(saleId, { pollingInterval })

  if (loading) return <div className="text-center my-5 py-5">Loading...</div>
  if (error) {
    if (error.message === "Sale not found") return <NotFound />
    return <div>Error...</div>
  }

  const saleProps = { sale, refetch }

  if (sale.assets.length > 1) {
    return <Multi {...saleProps} />
  } else {
    return <Single {...saleProps} />
  }
}

export default Sale
