const Media = () => {
  return (
    <div className="container mt-5 mb-5 pb-5">
      <div className="row pt-5 mt-5">
        <div className="col-md-6 offset-md-3">
          <h2 className="mb-5">Media Inquiries</h2>
        </div>
      </div>
    </div>
  )
}

export default Media
