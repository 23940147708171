import React from "react"
import ReactDOM from "react-dom"
import { HelmetProvider } from "react-helmet-async"
import { UALProvider } from "ual-reactjs-renderer"
import { Wax } from "@alienworlds/ual-wax"
import { Anchor } from "ual-anchor"

import App from "./App"
import store from "./app/store"
import { Provider } from "react-redux"
import * as serviceWorker from "./serviceWorker"

import getWaxEnv from "./app/getWaxEnv"

const waxEnv = getWaxEnv()

const WaxChain = {
  chainId: waxEnv.chainId,
  name: waxEnv.chainName,
  rpcEndpoints: [
    {
      protocol: waxEnv.rpcProtocol,
      host: waxEnv.rpcHost,
      port: waxEnv.rpcPort,
    },
  ],
}

const waxAuth = new Wax([WaxChain])
const anchorAuth = new Anchor([WaxChain], { appName: "paraffin.io" })

const waxAuthenticators = []
if (waxEnv.chainName === "WAX Mainnet") {
  waxAuthenticators.push(waxAuth)
}
waxAuthenticators.push(anchorAuth)

ReactDOM.render(
  <React.StrictMode>
    <UALProvider
      chains={[WaxChain]}
      authenticators={waxAuthenticators}
      appName={"paraffin.io"}
    >
      <Provider store={store}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </Provider>
    </UALProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
