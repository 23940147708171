import { Helmet } from "react-helmet-async"

const Roadmap = () => {
  return (
    <>
      <Helmet title="Roadmap" />
      <div className="container mt-5 mb-5 pb-5 roadmap">
        <div className="row pt-5 mt-5">
          <div className="col-md-6 offset-md-3">
            <h2 className="mb-5">Road Map</h2>
            <ul className="timeline">
              <li className="finished">
                <h4>Brand Identity</h4>
                <p>
                  Obtained paraffin.io. Paraffin is the primary ingredient in
                  candle wax. Candles provide both warmth and light. Thus, our
                  name is a nod to both the blockain upon which this marketplace
                  sits, and the emotion we associated with fine works of art.
                </p>
              </li>
              <li className="finished">
                <h4>Wireframe Application</h4>
                <p>
                  Desgin front end "Market" and "Sale" pages. Populate with
                  sample artwork found on the WAX blockchain.
                </p>
              </li>
              <li className="finished">
                <h4>Sale Page</h4>
                <p>
                  Enable "Sale" pages to dynamically pull asset and meta data
                  from WAX database by passing template ID. "Wire up" Buy Now
                  button to complete transactions with WAX wallet.
                </p>
              </li>
              <li className="finished">
                <h4>Static Pages</h4>
                <p>
                  Static pages include: Home, About, FAQ, Roadmap, Contact,
                  Submission, Media, and Terms of Service.
                </p>
              </li>
              <li className="finished">
                <h4>Market Page</h4>
                <p>
                  Enable "Market" page to dynamically populate with whitelisted
                  assets. Default sort by listing date. Include sort by price
                  and mint number.
                </p>
              </li>
              <li>
                <h4>Call for Submissions</h4>
                <p>
                  Reach out to the #WAXgang via Twitter to call for submissions
                  to be featured on the Paraffin Marketplace. Initially, assets
                  will be filtered by template ID.
                </p>
              </li>
              <li>
                <h4>NFT Creator</h4>
                <p>
                  Add NFT creator functionality to Paraffin so artists can mint
                  and list their own assets directly on Paraffin.
                </p>
              </li>
              {/*
            <li>
                <h4>TK</h4>
                <p>TK...</p>
            </li>
            */}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Roadmap
