import { createAsyncThunk } from "@reduxjs/toolkit"
import getMarketApi from "../../app/services/marketApi"

const fetchInventory = createAsyncThunk(
  "profile/fetchInventory",
  async (userName, _thunkAPI) => {
    if (!userName) {
      return null
    }

    const reponse = await getMarketApi().getAssets({
      owner: userName,
      order: "desc",
      sort: "transferred",
    })
    return reponse
  }
)

export { fetchInventory }
