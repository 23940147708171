const Home = () => {
  return (
    <>
      <div className="px-4 py-5 text-center video-container">
        <video
          id="background-video"
          muted
          defaultMuted
          playsInline
          autoPlay
          loop
          poster=""
        >
          <source src="img/playlist/header.mp4" type="video/mp4"></source>
        </video>
        <h1 className="display-1 mt-3 mb-2 text-dark">NFT Playlists</h1>
        <p className="lead mb-5 fw-bold text-light text-shadow-sm ">
          Mixes that you really own on the WAX Blockchain.
        </p>
        <div className="col-lg-6 mx-auto">
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <a href="playlist/builder">
              <button
                type="button"
                className="btn btn-light btn-lg px-4 gap-3 btn-outline-secondary"
              >
                Mint One Now
              </button>
            </a>
          </div>
        </div>
        <img
          src="img/playlist/wax_logo.png"
          className="wax-logo mt-5"
          alt="WAX Logo"
        />
      </div>

      <div className="bg-salmon text-light py-5">
        <div className="container how-to py-5">
          <div className="row text-center">
            <div className="col-sm-4 px-5">
              <i className="bi bi-brush"></i>
              <h5>Design Cover Art</h5>
              <p>
                Use your favorite design tool to create cover art. We suggest
                using{" "}
                <a
                  href="https://canva.com"
                  title="Canva"
                  target="_blank"
                  rel="noreferrer"
                >
                  Canva
                </a>
                .
              </p>
            </div>
            <div className="col-sm-4 px-5">
              <i className="bi bi-file-music"></i>
              <h5>Build a Playlist</h5>
              <p>
                Use apps like Spotify or SoundCloud to build a playlist with
                your favorite songs.
              </p>
            </div>
            <div className="col-sm-4 px-5">
              <i className="bi bi-arrow-up-circle"></i>
              <h5>Mint NFT</h5>
              <p>Mint your NFT Playlist to the WAX Blockchain.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="row">
          <h2>Featured</h2>
        </div>
        <div className="row text-center">
          <div className="col-md-4 col-sm-6 my-3">
            <a href="featured.html">
              <img
                src="img/playlist/90s-jams.jpg"
                className="img-fluid cover-art"
                alt="Placeholder"
              />
            </a>
          </div>
          <div className="col-md-4 col-sm-6 my-3">
            <img
              src="img/playlist/holiday-mix.gif"
              className="img-fluid cover-art"
              alt="Placeholder"
            />
          </div>
          <div className="col-md-4 col-sm-6 my-3">
            <img
              src="img/playlist/into-the-void.jpg"
              className="img-fluid cover-art"
              alt="Placeholder"
            />
          </div>
          <div className="col-md-4 col-sm-6 my-3">
            <img
              src="img/playlist/yacht-rock.jpg"
              className="img-fluid cover-art"
              alt="Placeholder"
            />
          </div>

          <div className="col-md-4 col-sm-6 my-3">
            <img
              src="img/playlist/b-sides.jpg"
              className="img-fluid cover-art"
              alt="Placeholder"
            />
          </div>
          <div className="col-md-4 col-sm-6 my-3">
            <img
              src="img/playlist/sea-shanties.png"
              className="img-fluid cover-art"
              alt="Placeholder"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
