import { Helmet } from "react-helmet-async"

const TOS = () => {
  return (
    <>
      <Helmet title="TOS" />
      <div className="container mt-5 mb-5 pb-5 content-static tos">
        <div className="row pt-5 mt-5">
          <div className="col mb-5">
            <h2>Website Terms and Conditions of Use</h2>
            <h4>1. Terms</h4>
            <p>
              By accessing this Website, accessible from https://paraffin.io,
              you are agreeing to be bound by these Website Terms and Conditions
              of Use and agree that you are responsible for the agreement with
              any applicable local laws. If you disagree with any of these
              terms, you are prohibited from accessing this site. The materials
              contained in this Website are protected by copyright and trade
              mark law.
            </p>
            <h4>2. Use License</h4>
            <p>
              Permission is granted to temporarily download one copy of the
              materials on Paraffin's Website for personal, non-commercial
              transitory viewing only. This is the grant of a license, not a
              transfer of title, and under this license you may not:
            </p>
            <ul>
              <li>modify or copy the materials;</li>
              <li>
                use the materials for any commercial purpose or for any public
                display;
              </li>
              <li>
                attempt to reverse engineer any software contained on Paraffin's
                Website;
              </li>
              <li>
                remove any copyright or other proprietary notations from the
                materials; or
              </li>
              <li>
                transferring the materials to another person or "mirror" the
                materials on any other server.
              </li>
            </ul>
            <p>
              This will let Paraffin to terminate upon violations of any of
              these restrictions. Upon termination, your viewing right will also
              be terminated and you should destroy any downloaded materials in
              your possession whether it is printed or electronic format.
            </p>
            <h4>3. Disclaimer</h4>
            <p>
              All the materials on Paraffin’s Website are provided "as is".
              Paraffin makes no warranties, may it be expressed or implied,
              therefore negates all other warranties. Furthermore, Paraffin does
              not make any representations concerning the accuracy or
              reliability of the use of the materials on its Website or
              otherwise relating to such materials or any sites linked to this
              Website.
            </p>
            <h4>4. Limitations</h4>
            <p>
              Paraffin or its suppliers will not be hold accountable for any
              damages that will arise with the use or inability to use the
              materials on Paraffin’s Website, even if Paraffin or an authorize
              representative of this Website has been notified, orally or
              written, of the possibility of such damage. Some jurisdiction does
              not allow limitations on implied warranties or limitations of
              liability for incidental damages, these limitations may not apply
              to you.
            </p>
            <h4>5. Revisions and Errata</h4>
            <p>
              The materials appearing on Paraffin’s Website may include
              technical, typographical, or photographic errors. Paraffin will
              not promise that any of the materials in this Website are
              accurate, complete, or current. Paraffin may change the materials
              contained on its Website at any time without notice. Paraffin does
              not make any commitment to update the materials.
            </p>
            <h4>6. Transactions</h4>
            <p>
              All transactions on the Paraffin Platform, including but not
              limited to transfers, offers, bids, listings, sales, or purchases
              of items are initiated through one or more Smart Contract(s). The
              smart Contract(s) are configured to facilitate the execution of a
              user offer, an acceptance of an offer, or other confirmation to
              purchase, sell, bid on, list, or transfer an item.
            </p>
            <p>
              The User understands that Paraffin neither own nor control any
              other third-party site, product, or service that you might access,
              visit, or use for the purpose of enabling you to use the various
              features of the Paraffin Platform. Paraffin will not be liable for
              the acts or omissions of any such third parties, nor will we be
              liable for any damage that you may suffer because of your
              transactions or any other interaction with any such third parties.
            </p>
            <p>
              items are optionally offered for immediate acceptance at a List
              Price established by the Seller. Users can purchase items with a
              List Price through the platform by sending an equivalent amount of
              Cryptocurrency to a Smart Contract configured to initiate a
              transfer of the items.
            </p>
            <p>
              Users can make offers on all listed items through the platform.
              Offers on the items Platform are legally binding, revocable offers
              to purchase the Item capable of immediate acceptance by the Owner
              of the items. By making an offer, the User agrees to temporarily
              send and lose control over an amount of offered WAX to a Smart
              Contract. The Smart Contract is configured to hold the offered WAX
              until either the offer is accepted by the Owner of the items, a
              higher offer is received, or the offer is revoked.
            </p>
            <p>
              In an Auction, the Seller establishes a specific start and end
              time for an Auction for an item. Seller may optionally include a
              Starting Price. As long as the Starting Price is met by an Auction
              Participant, the items will be sold. Items without a Starting
              Price will be sold to the highest bidder, no matter what the bid
              is. An Auction may be cancelled prior to the start of the auction,
              i. e. when it is counting down to kick off.
            </p>
            <h4>7. Prices and Fees</h4>
            <p>
              Paraffin does not store, send, or receive items. Any transfer
              occurs within the supporting blockchain and not in the name or on
              behalf of Paraffin.
            </p>
            <p>
              Every transaction on the Paraffin Platform is subject to secondary
              market Fees. Paraffin collects 2% of the total sale price as a
              platform commission. The fee is deducted from the total Sale Price
              at the time of the purchase by operation of the Smart Contract(s).
              Further fees can be royalty fees to the creator of the item or
              blockchain fees. However, these are not taken "in addition to the
              sales price," but instead deducted from the sales price directly.
            </p>
            <p>
              The User agrees and understands that all fees, commissions, and
              royalties are transferred, processed, or initiated directly
              through one or more of the Smart Contracts on the blockchain
              network. By transacting on the platform and by using the Smart
              Contracts, the User hereby acknowledges, consents to, and accepts
              all automated fees, commissions, and royalties for the sale of
              Items on the Paraffin Platform.
            </p>
            <p>
              The User hereby consents to and agrees to be bound by the Smart
              Contracts’ execution and distribution of the fees, commissions,
              and royalties. Users waive any entitlement to royalties,
              commissions, or fees paid to another by operation of the Smart
              Contracts. All sales of items will be automated, collected, and
              disbursed to the seller.
            </p>
            <h4>8.Obligations</h4>
            <p>
              The User affirm that they are over the age of 18, as the Paraffin
              Platform is not intended for people under 18. If you are under 18,
              you need the consent of the parent or guardian.
            </p>
            <p>
              The users are solely responsible for determining what, if any,
              taxes apply to any transactions (including, without limitation,
              any taxes that may become payable as the result of the ownership,
              transfer, purchase, sale, or creation of any items. Paraffin is
              therefore, not responsible for determining the taxes that apply to
              items transactions.
            </p>
            <p>
              You agree to use the Paraffin Platform only for purposes that are
              legal, proper and in accordance with these Terms and any
              applicable laws or regulations. Without limitation, you may not,
              and may not allow any third party to:
            </p>
            <ul>
              <li>
                send, upload, transmit, post, distribute, disseminate, or
                otherwise make available through the Platform any unlawful,
                defamatory, harassing, abusive, fraudulent, obscene, or
                otherwise objectionable content.
              </li>
              <li>
                send, upload, transmit, post, distribute, disseminate, or
                otherwise make available through the Platform any content that
                infringes the intellectual proprietary or legal rights of any
                party or others.
              </li>
              <li>
                modify, adapt, translate, or reverse engineer any portion of the
                Paraffin Platform or engaging in any attack, hack,
                denial-of-service attack, interference, or exploit of any
                Paraffin smart contracts.
              </li>
              <li>
                display any content on the Platform that contains any
                hate-related or violent content or contains any other material,
                products or services that violate or encourage conduct that
                would violate any criminal laws, any other applicable laws, or
                any third-party rights.
              </li>
              <li>place misleading bids or offers.</li>
            </ul>
            <p>
              Failure to abide by these and the following Terms may result in,
              without limitation, suspension, or deletion of the Paraffin
              account. Paraffin has the authority and discretion to remove,
              suspend, or revoke users’ access to the Paraffin Platform or any
              other aspect of the Paraffin Platform.
            </p>
            <h4>9. Intellectual property and ownership of rights</h4>
            <p>
              Paraffin owns all legal rights in and to all elements of the
              Paraffin Platform, e.g. design, systems, compilation of the
              content, code and all other elements of the Paraffin Platform are
              owned by Paraffin.
            </p>
            <p>
              You are solely responsible for your use of the Paraffin Services
              and for any Content you provide, including compliance with
              applicable laws, rules, and regulations.
            </p>
            <p>
              You retain your rights to any Content you submit, post, or display
              using the Services.
            </p>
            <p>
              By submitting, posting, or displaying crypto/digital
              assets/Content/items on or through the Paraffin Platform, you
              grant us a worldwide, non-exclusive, simple usage, royalty-free
              license (with the right to sublicense) to use, copy, reproduce,
              process, adapt, modify, publish, transmit, display, and distribute
              such Content in any and all media or distribution methods. This
              license authorizes us to make your items available to the rest of
              the world and to let others do the same.
            </p>
            <p>
              The user represents and warrant that they have, or have obtained,
              all rights, licenses, consents, permissions, power and/or
              authority necessary to grant the rights granted herein for any
              items that they submit, post, or display on or through the
              Paraffin Services. You agree that items will not contain material
              subject to copyright or other proprietary rights, unless you have
              necessary permission or are otherwise legally entitled to post the
              material and to grant Paraffin the license described above.
            </p>
            <p>
              The right of the user to offer, sell, purchase, display, etc. the
              item on other platforms remains unaffected in any case.
            </p>
            <p>
              If and to the extent the User makes use of Paraffin's whitelist
              and verification system, Paraffin's review and evaluation will be
              limited primarily to technical requirements for the item. Such
              evaluation does not constitute any legal review by Paraffin. In
              particular, such verification by Paraffin does not imply that the
              User, provider, purchaser, etc. is also the actual legal owner or
              actually has the necessary rights with regard to the item. In this
              case, the user remains obliged to convince himself/herself of the
              ownership of the required rights.
            </p>{" "}
            <p>
              You agree to indemnify and hold harmless our company and its
              affiliates from and against any and all claims, costs,
              proceedings, demands, losses, damages, and expenses (including,
              without limitation, reasonable attorney’s fees and legal costs) of
              any kind or nature, arising from or relating to, any actual or
              alleged breach of these Terms by you, a co-conspirator, or anyone
              using your account. If we assume the defense of such a matter, you
              will reasonably cooperate with us in such defense.
            </p>
            <p>
              If you become aware of the creation, listing, or buying of assets
              in violation of any of the terms, you shall contact us at
              support@paraffin.io to report it.
            </p>{" "}
            <p>
              Paraffin reserves the right to remove content without prior
              notice. Paraffin will take down works in response to formal
              infringement claims and will terminate a user's access to the
              Services if the user is determined to be a repeat infringer.
            </p>
            <p>
              Any person who knowingly materially misrepresents that Content or
              an activity is infringing or that any material or activity was
              removed or disabled by mistake or misidentification, shall be
              liable to us and possibly others for any damages, including costs
              and attorneys’ fees incurred by us in removing or disabling access
              to the material or activity claimed to be infringing or in
              replacing the removed material or enabling access to it.
            </p>
            <p>
              As part of Paraffin Marketplace service, Paraffin acts as a mere
              intermediary between Sellers and Buyers. Therefore, the services
              provided through Paraffin takes place only between the Buyer and
              the Seller and any claim must be addressed by the Buyer to the
              Seller. The Seller is solely responsible for the claims
              processing. Nevertheless, Paraffin reserves the right (but is not
              obliged) to take part in arbitration, in its discretion, if the
              Seller does not provide a response to a request nor a settlement
              to a dispute.
            </p>
            <h4>10. Liability</h4>
            <p>
              The limitations and notices of Paraffin's liability set forth
              above shall prevail.
            </p>
            <p>
              Besides this, Paraffin is liable for damages caused intentionally
              or by gross negligence, according to the product liability law and
              due to an assumed guarantee as well as in case of injury to life,
              body, or health.
            </p>
            <p>
              Any liability of Paraffin, its legal representatives and vicarious
              agents for slight negligence is excluded. This shall only not
              apply in the event of a culpable breach of fundamental contractual
              obligations, i. e. obligations the fulfillment of which is
              essential for the proper performance of this contractual
              relationship or the breach of which would jeopardize the
              achievement of the purpose of the contract. In these cases,
              however, Paraffin's liability shall be limited to the amount of
              the foreseeable damage typical for this type of contract.
            </p>
            <p>
              Paraffin`s liability for damages under this Agreement shall in all
              cases be limited to, and under no circumstances shall exceed, the
              amount of proceeds of the User actually received by Paraffin
              arising out of the damage after deducting all costs, fees and
              liabilities incurred by or on behalf of Paraffin.
            </p>
            <p>
              Besides the previously mentioned, there shall be no further
              liability. This shall also apply to loss of profit, loss of
              savings or other consequential damages. The above limitations of
              liability also apply to the personal liability of employees,
              representatives, and bodies of Paraffin.
            </p>
            <h4>11. Links</h4>
            <p>
              Paraffin has not reviewed all of the sites linked to its Website
              and is not responsible for the contents of any such linked site.
              The presence of any link does not imply endorsement by Paraffin of
              the site. The use of any linked website is at the user’s own risk.
            </p>
            <h4>12. Site Terms of Use Modifications</h4>
            <p>
              Paraffin may revise these Terms of Use for its Website at any time
              without prior notice. By using this Website, you are agreeing to
              be bound by the current version of these Terms and Conditions of
              Use.
            </p>
            <h4>13. Your Privacy</h4>
            <p>Please read our Privacy Policy.</p>
            <h4>14. Governing Law</h4>
            <p>
              Any claim related to Paraffin's Website shall be governed by the
              laws of af without regards to its conflict of law provisions.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default TOS
